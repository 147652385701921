.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#map {
  position: static;
  width: 100vw;
  height: 100vh;
}